footer .footerH2 {
  line-height: 6rem; /* Zorg ervoor dat de lijnhoogte past bij de hoogte */
  margin: 0; /* Verwijder ongewenste marges */
  padding: 0;
  display: inline-block;
}

.footerH2 .menu__letters span {
  transform-origin: center bottom; /* Zorg dat de transformatie beter uitlijnt */
  margin-right: 0.1rem; /* Voeg een beetje ruimte toe tussen de letters */
}

.footerH2 .menu__letters:hover span {
  transform: translate3d(0, -150%, 0) rotateX(0deg); /* Pas aan naar een subtielere beweging */
}

.footerH2 .menu__letters span {
  transition-delay: calc(
    0.05s * var(--index)
  ); /* Verhoog de vertraging voor meer zichtbare animatie */
}

@media (max-width: 767px) {
  footer .footerH2 {
    line-height: 3.5rem;
  }
}
