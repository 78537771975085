@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Space Grotesk", sans-serif;
}

@font-face {
  font-family: "CF Integral";
  src: url("../public/fonts/IntegralCF-Bold.woff") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("../public/fonts/SpaceGrotesk-Regular.ttf") format("woff2");
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("../public/fonts/SpaceGrotesk-Medium.ttf") format("woff2");
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("../public/fonts/SpaceGrotesk-Bold.ttf") format("woff2");
  font-weight: 700; /* Bold */
  font-style: normal;
}

h1 {
  display: flex;
  align-items: center;
  gap: 1rem;
}

::selection {
  background-color: #3fc473;
  color: white;
}

.last-name {
  transition: transform 0.3s ease; /* Maakt de beweging soepel */
}

h1 {
  font-family: "CF Integral", sans-serif;
  font-size: 6rem;
  color: #3fc473;
  vertical-align: super;
}
.h1 {
  font-family: "CF Integral", sans-serif;
  font-size: 6rem;
}

@media (max-width: 991px) {
  h1,
  .h1 {
    font-size: 4rem;
  }
}

@media (max-width: 767px) {
  h1,
  .h1 {
    font-size: 3rem;
  }
}

@media (max-width: 480px) {
  h1,
  .h1 {
    font-size: 2.5rem;
  }
}

.ball {
  font-size: 1rem;
  color: #3fc473;
  animation: flicker 1s infinite alternate ease-in-out;
}

@keyframes flicker {
  0%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

/* Add this to your global CSS file (e.g., index.css) for the dots animation */

@keyframes dots {
  0% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
  100% {
    content: "";
  }
}

.dots::after {
  content: "";
  display: inline-block;
  animation: dots 1.5s steps(3, end) infinite;
}

@keyframes scale-a-lil {
  from {
    scale: 0.9;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .test img {
    animation: scale-a-lil linear both;
    animation-timeline: view();
    animation-range: 25vh 75vh;
  }
}
