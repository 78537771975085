.talen img {
  filter: grayscale(100%);
  transition: filter 0.4s ease, transform 0.4s ease, box-shadow 0.4s ease;
  background-image: #3fc473;
}

.talen img:hover {
  filter: grayscale(0%);
  transform: scale(1.1);
}
