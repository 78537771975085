.menu__item {
  position: relative;
  cursor: pointer;
}

.menu__letters .h2 {
  font-weight: 500;
}

.menu__item-text {
  pointer-events: auto;
  position: relative;
  font-size: 1rem;
  text-decoration: none;
  color: black;
  font-weight: bold;
  transition: opacity 0.3s ease;
}

.menu__letters {
  display: inline-flex; /* Zorgt ervoor dat de letters naast elkaar blijven */
  perspective: 1000px;
  transform-style: preserve-3d;
  overflow: hidden;
  position: relative;
}
.menu__letters.clone {
  position: absolute;
  top: 0;
  left: 0;
}

.menu__letters span {
  will-change: transform;
  transform-style: preserve-3d;
  display: inline-flex; /* Voorkomt dat de letters breken op kleinere schermen */
  transform-origin: bottom;
  transition: transform 0.5s ease;
  transition-delay: calc(0.05s * var(--index));
  backface-visibility: hidden; /* Verbetert rendering in Firefox en Safari */
}

.menu__letters.clone span {
  transform-origin: top;
  transform: translate3d(0, 100%, 0) rotateX(90deg);
  backface-visibility: hidden;
}

.menu__item:hover .menu__letters span {
  transform: translateY(-100%);
}

.menu__item:hover .menu__letters.clone span {
  transform: translateY(0%);
}
.contact-button {
  position: relative;
  padding: 0.35rem 0.8rem;
  font-weight: bold;
  font-size: 0.875rem;
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
  transition: background-color 0.15s ease-out, color 0.15s ease-out;
}

.contact-left::before,
.contact-left::after {
  content: "";
  border: 1px solid black;
  position: absolute;
  height: 0.25rem;
  width: 0.25rem;
  transition: all 0.15s ease-out;
}

.contact-left::before {
  border-right: none;
  border-bottom: none;
  top: 0;
  left: 0;
}

.contact-left::after {
  border-right: none;
  border-top: none;
  bottom: 0;
  left: 0;
}

.contact-right::before,
.contact-right::after {
  content: "";
  border: 1px solid black;
  position: absolute;
  height: 0.25rem;
  width: 0.25rem;
  transition: all 0.15s ease-out;
}

.contact-right::before {
  border-left: none;
  border-bottom: none;
  top: 0;
  right: 0;
}

.contact-right::after {
  border-left: none;
  border-top: none;
  bottom: 0;
  right: 0;
}

.contact-button:hover .contact-left::before {
  transform: translate(4px, 4px);
}

.contact-button:hover .contact-left::after {
  transform: translate(4px, -4px);
}

.contact-button:hover .contact-right::before {
  transform: translate(-4px, 4px);
}

.contact-button:hover .contact-right::after {
  transform: translate(-4px, -4px);
}
